<template>
    <div>
        <div id="feedback">
            <div class="card">
                <h3 class="card_title">Feedback/Featureanfrage</h3>
                <div class="padding">
                    <textarea class="padding_small radius" name="" id="" cols="30" rows="10" v-model=feedback_message></textarea>
                    <p class="blue padding_small radius fitContent pointer" @click="submit">{{feedback_response}}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Feedback",
    data() {
        return {
            feedback_message: "",
            feedback_response: "Senden",
        }
    },
    methods: {
        submit() {
            var formData = new FormData();
            formData.append('method', 'feedback_submit');
            formData.append('text', this.feedback_message);
            // fetch('https://primecalc.de/assets/php/data_get.php', {
            //     method: 'POST',
            //     body: formData
            // }).then(response => response.json()).then(response => {
            //     this.feedback_response = response == 1 ? "Vielen Dank" : "Ein Fehler ist aufgetreten"
            // })            
            fetch(this.$store.getters.path_data, {
                method: 'POST',
                body: formData
            }).then(response => response.json()).then(response => {
                this.feedback_response = response == 1 ? "Vielen Dank" : "Ein Fehler ist aufgetreten"
            })
        }
    }
}
</script>

<style>

</style>